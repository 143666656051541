import { Component, Input, type SimpleChanges, ViewEncapsulation } from '@angular/core';
import { type Company } from '../../models/company';
import { type ConnectedUser } from '../../models/user';
import { v4 as uuidv4 } from 'uuid';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';

@Component({
  selector: 'company-logo',
  templateUrl: './company-logo.component.html',
  styleUrls: ['./company-logo.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [AssetSrcDirective],
})
export class CompanyLogoComponent {
  @Input() company: Company | ConnectedUser;

  public logoUrl: string;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.company) {
      this.logoUrl = this.getLogoUrl();
    }
  }

  public getLogoUrl(): string {
    if (!this.company) {
      return null;
    }

    const logoUrl = (this.company as Company).logoUrl || (this.company as ConnectedUser).companyLogoUrl;
    if (!logoUrl) {
      return null;
    }

    if (logoUrl.includes('http')) {
      return `${logoUrl}?v=${uuidv4()}`;
    } else {
      return logoUrl;
    }
  }
}
